<template>
  <div class="c-troubleshoot__form">
    <div class="c-troubleshoot__form_content">
      <div class="c-troubleshoot__form_content-input-placeholder"><input type="text" class="c-troubleshoot__form_content-input-placeholder-input" required v-model="email"><div class="placeholder">Email address<span>*</span></div></div>
      <div class="c-troubleshoot__form_content-input-placeholder"><input type="text" class="c-troubleshoot__form_content-input-placeholder-input" required v-model="subject"><div class="placeholder">Subject<span>*</span></div></div>
      <div class="c-troubleshoot__form_content-input-placeholder"><input type="text" class="c-troubleshoot__form_content-input-placeholder-input" required v-model="description"><div class="placeholder">Description<span>*</span></div></div>
      <div class="c-troubleshoot__form_content-text">Please enter the details of your request. A member of our support staff will respond as soon as possible.</div>
      <div class="c-troubleshoot__form_content-dropdown" v-click-outside="hideDropdown">
        <div class="c-troubleshoot__form_content-dropdown-header" :class="{'c-troubleshoot__form_content-dropdown-header-black-color' : dataDropdown != 'Concern'}" @click="showDropdown()">
          {{dataDropdown}}<span class="c-troubleshoot__form_content-dropdown-header-red" v-if="dataDropdown == 'Concern'">*</span>
          <img class="c-troubleshoot__form_content-dropdown-icon" :src="!dropdownVisible ? require('@/assets/images/dropdown-down-icon.svg') : require('@/assets/images/dropdown-up-icon.svg')" alt="dropdown-concern">
        </div>
        <div class="c-troubleshoot__form_content-dropdown-container" v-if="dropdownVisible">
          <div class="c-troubleshoot__form_content-dropdown-container-list" v-for="item in dropdownList" :key="item.id" @click ="selectValue(item)">
            {{item.text}}
          </div>
        </div>
      </div>
      <div class="c-troubleshoot__form_content-text c-troubleshoot__form_content-text-attachments">Attachments :</div>
      <div class="c-troubleshoot__form_upload-box" v-if="!uploadedName" @dragover="dragover" @dragleave="dragleave" @drop="drop">
        <input type="file" multiple class="c-troubleshoot__form_upload-box-input" id="FileUpload1" ref="attachments" @click="addUploadedFile" @change="onChange">
        <div class="c-troubleshoot__form_upload-box-text">
          <span class="c-troubleshoot__form_upload-box-text-blue" @click="clickableAddFile">Add file</span> or drop file here
        </div>
      </div>
      <div class="c-troubleshoot__form_upload-box-uploaded" v-else>
        <input id="attachmentFile" class="c-troubleshoot__form_upload-box-input-uploaded" type="text" :value="uploadedName" disabled />
        <div class="c-troubleshoot__form_upload-box-input-button">
          <img src="@/assets/images/close.svg" alt="close" class="c-troubleshoot__form_upload-box-input-button-closed" @click="closeUploadedFile">
        </div>
        <div class="c-troubleshoot__form_upload-box-progress" v-if="uploadingState">
          <div class="c-troubleshoot__form_upload-box-size">
            {{uploadedSize}} MB
          </div>
          <div id="upload" class="c-troubleshoot__form_upload-box-progressbox">
          </div>
        </div>


        <!-- <div class="c-troubleshoot__form_upload-box-progress">
          <progress id="progressBar" value="0" max="100" style="width:526.62px;"></progress>
        </div> -->

      </div>
      <div class="c-getstarted__body-form-button">
        <button class="c-troubleshoot__form_button-submit" :class="{'c-troubleshoot__body-form-button-submit_disable':!validForm}" @click="uploadAttachment">Submit</button>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import vClickOutside from 'v-click-outside'
  export default {
    data() {
      return {
        description: '',
        subject: '',
        email: '',
        dataDropdown:'Concern',
        uploadedFile: {},
        uploadedName:'',
        uploadingState: true,
        uploadedSize:'',
        uploadedFileSuccess:false,
        statusUploadFile:false,
        dropdownVisible:false,
        dropdownList: [
          {
            id: 0,
            text: 'Account Onboarding Status',
            value: 'account'
          },
          {
            id: 1,
            text: 'Security and Verification',
            value: 'security'
          },
          {
            id: 2,
            text: 'Transactions and Settlements',
            value: 'transactions'
          },
          {
            id: 3,
            text: 'Technical and Navigation Errors',
            value: 'technical'
          },
          {
            id: 4,
            text: 'Deposits and Withdrawals',
            value: 'deposits'
          },
          {
            id: 5,
            text: 'Service Standards',
            value: 'service'
          },
          {
            id: 6,
            text: 'Regulatory and Compliance',
            value: 'regulatory'
          }
        ],
        uploadFile: '',
        fileType1: '',
        name: '',
        fileMaxSizeByte: '',
        fileMaxSize: '2',
        imgName: '',
        countImg: '',
        filelist: [],
        attachmentResponse:null,
        concernValue: ''
      }
    },
    mounted() {
      // var fu1 = document.getElementById("FileUpload1");
      // alert("You selected " + fu1.value);

    },
    watch:{
      uploadingCheck:function(){
        const uploadBar = document.getElementById("upload");

        uploadBar.addEventListener("animationed", () => {
            this.uploadingState = false;
        });
      }
    },
    computed:{
      validForm:function(){
        return (this.description && this.subject && this.email && this.dataDropdown && this.dataDropdown != 'Concern')
      }
    },
    directives: {
      clickOutside: vClickOutside.directive
    },
    methods: {
      onChange() {
        let _this = this
        this.filelist = [...this.$refs.attachments.files];
        this.addUploadedFile()
        this.uploadedName = this.filelist[0].name
        this.uploadedName = this.filelist[0].name
        this.uploadedSize = (this.filelist[0].size / (1024*1024)).toFixed(1);
        setTimeout(function(){
          _this.uploadingState = false;
        },2500)
      },
      remove(i) {
        this.filelist.splice(i, 1);
      },
      dragover(event) {
        event.preventDefault();
      },
      dragleave(event) {
        event.preventDefault();
      },
      drop(event) {
        event.preventDefault();
        this.$refs.attachments.files = event.dataTransfer.files;
        this.onChange();
      },
      showDropdown(){
        this.dropdownVisible= !this.dropdownVisible;
      },
      selectValue(item){
        this.hideDropdown();
        this.dataDropdown = item.text
        this.concernValue = item.value
      },
      hideDropdown(){
        this.dropdownVisible = false;
      },
      getBinary(){
        return new Promise(resolve => {
          var reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result);
          };
          reader.readAsBinaryString(this.uploadedFile);
        })
      },
      uploadAttachment: async function(){
        let _this = this;
        if(!this.uploadedName){
          this.submitData();
        }
        else{
          $.ajax({
            url: 'https://sparrowexchange.zendesk.com/api/v2/uploads.json?filename='+this.uploadedName,
            type: 'POST',
            data: this.uploadedFile,
            dataType: 'json',
            processData: false,
            contentType: 'application/binary',
            headers: {
              "Authorization": 'Basic '+ btoa(this.email+'/token:wsKKehYNUnNGEJ0CaEOdebnUodQh0wxMvZ012crx'),
            },
            success: function(response) {
              _this.attachmentResponse = response
              _this.submitData()
            }
          })
        }
      },
      submitData() {
        let headersData = {
          'Accept': "application/json",
          'Content-Type': "application/json"
        }
        let requestData = {
          "requester":{name:this.email},
          'subject': this.subject,
          "comment": {"body": this.description},
          "custom_fields": {"360014691931": this.concernValue}
        }
        if(this.attachmentResponse){
          requestData.comment.uploads = [this.attachmentResponse.upload.token]
        }
        this.$http.post(this.createApiUrlChestNut('system/troubleshoot'), requestData, {headers:headersData}).then(response =>{
          console.log(response)
          this.$eventHub.$emit('show-alert', true, {title: 'Thank you for your query and interest in Sparrow`s digital asset wealth management solutions.', body: 'Our team will be in touch with you shortly.'}, true)
          this.resetForm()
        },err => {
          console.log(err)
        });
      },
      clickableAddFile() {
        let _this = this;
        _this.$refs.attachments.click()
      },
      addUploadedFile: function(){
        let _this = this;
        const fileUploader = document.getElementById('FileUpload1');

        fileUploader.addEventListener('change', (event) => {
          const files = event.target.files;
          const file = files[0];
          this.uploadedFile = file;
          // reader.readAsDataURL(file);
          this.uploadedName = file.name
          this.uploadedSize = (file.size / (1024*1024)).toFixed(1);

          setTimeout(function(){
            _this.uploadingState = false;
          },2500)
          // console.log(file.name)
        })

        // var name = document.getElementById('attachmentFile');
        // this.uploadedName = name.files.item(0).name
        // this.uploadedFile = file;
      },
      closeUploadedFile: function() {
        this.uploadedName = ""
        this.uploadingState = true
        this.attachmentResponse = null
        // this.uploadedFileSuccess = false
        // this.uploadedFile = {}
      },
      resetForm: function(){
        this.closeUploadedFile()
        this.description = ''
        this.subject = ''
        this.email = ''
        this.dataDropdown = 'Concern'
        this.concernValue = ''
      }
    }
  }
</script>
