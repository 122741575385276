<template>
  <div class="page">
    <div class="c-page o-page-wrapper c-page_troubleshoot">
      <Sidebar :status="'support'" :elementClass="'false'"></Sidebar>
      <div class="o-sidebar-content">
        <PageBanner :bannerData = "bannerData" :theme="theme"></PageBanner>
        <div class="c-page__content">
          <TroubleshootCenterContent></TroubleshootCenterContent>
          <TroubleshootCenterForm></TroubleshootCenterForm>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Sidebar from '@/components/Sidebar.vue'
import Footer from '@/components/Footer.vue'
import PageBanner from '@/components/PageBanner.vue'
import TroubleshootCenterContent from '@/components/support/TroubleshootCenterContent.vue'
import TroubleshootCenterForm from '@/components/support/TroubleshootCenterForm.vue'
export default {
  name: 'TroubleshootCenter',
  components: {
    Sidebar,
    Footer,
    PageBanner,
    TroubleshootCenterForm,
    TroubleshootCenterContent
  },
  data() {
    return {
      bannerData: [
        {
          img : require('@/assets/images/banner-6.svg'),
          title:"<span class='c-page-banner__regular c-page-banner__regular-longtext'>Sparrow aims to meet</span>",
          subtitle:"<span class='c-page-banner__bold c-page-banner__mobile'>the highest compliance <br />and operational standards</span>",
          buttonText:"Find out more >",
          buttonPage:"/legal/regulation"
        },
      ],
      theme: 'two',
    }
  },
  mounted:function(){
    this.updateTitleMeta('Sparrow | Address Your Issues and Queries at Sparrow Support Center')
    this.updateDescriptionMeta('Still experiencing troubleshoots? Use the Sparrow Help Center to get help with your account. Submit a help request and we will be in touch with you shortly.')
    this.updateKeywordMeta('sparrow')
  }
}
</script>
